<template>
  <div class="my-6">
    <BrowseSection />
  </div>
</template>

<script>
import BrowseSection from "../components/breederdirectory/browse/BrowseSection.vue";

export default {
  components: {
    BrowseSection,
  },
};
</script>