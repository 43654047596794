<template>
  <div>
    <div
      class="w-36 md:w-56 h-56 bg-white p-4 mt-4 box-content font-proximaMedium cursor-pointer"
      @click="whichBreed(service)"
      @mouseover="mouseOverFn"
      @mouseleave="mouseLeaveFn"
    >
      <div class="h-full">
        <div v-if="!showMessage" class="flex justify-end">
          <div
            class="w-10 h-10 bg-primary text-white rounded-full flex items-center justify-center"
          >
            <div>{{ formattedIndex(i) }}</div>
          </div>
        </div>
        <div v-else class="flex justify-center">
          <div
            class="w-10 h-10 bg-primary text-white rounded-full flex items-center justify-center"
          >
            <div>{{ formattedIndex(i) }}</div>
          </div>
        </div>
        <img
          v-if="!showMessage"
          class="pb-2"
          :src="
            require('@/assets/images/dogbreederservices/' +
              serviceList[i] +
              '.png')
          "
          :alt="serviceList[i]"
        />
        <div v-else class="flex items-center justify-center">
          <div class="mt-12">{{ service.group }}</div>
        </div>
      </div>
    </div>
    <ModalsConfirmVue :show="dogBreedListModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-6 -right-10 text-3xl font-proximaMedium cursor-pointer z-10"
          @click="closeModals"
        >
          ✖
        </div>
        <!-- <h1 class="text-2xl">Favorite Services</h1> -->
      </div>
      <div slot="actionText" class="mt-6">
        <div class="mb-4" v-for="(data, i) in service.sections" :key="i">
          <div class="my-4">
            <div
              class="text-xl text-primary font-semibold font-proximaMedium text-left rounded-md"
            >
              {{ "Section " + Number(i + 1) + " - " + data.section }}
            </div>
            <ul class="font-proximaLight text-base">
              <li
                class="py-2 cursor-pointer"
                v-for="(data, i) in data.breeds"
                :key="i"
                @click="choosenDog(data)"
              >
                {{ data.breedName }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirmVue>
  </div>
</template>

<script>
import ModalsConfirmVue from "../../modals/ModalsConfirm.vue";
import DogBreeds from "../../../json/DogBreeds.json";

export default {
  components: {
    ModalsConfirmVue,
  },
  props: ["service", "i"],
  data() {
    return {
      showMessage: false,
      dogBreeds: DogBreeds,
      dogBreedListModals: false,
      breedMatch: [],
      serviceList: [
        "1-AustralianCattleDog1",
        "2-Dobermann1",
        "3-JackRusselTerrier1",
        "4-Dachsund1",
        "5-SiberianHusky1",
        "6-Beagle1",
        "7-IrishSetter1",
        "8-Labrador1",
        "9-Poodle1",
        "10-Saluki1",
      ],
    };
  },
  methods: {
    formattedIndex(index) {
      return (index + 1).toString().padStart(2, "0");
    },
    mouseOverFn() {
      this.showMessage = true;
    },
    mouseLeaveFn() {
      this.showMessage = false;
    },
    whichBreed(service) {
      // console.log(i);
      // console.log(service.title);
      const targetTitle = service.group;
      console.log(this.service);
      /*  this.breedMatch = this.service.find(
        (obj) => obj.group === targetTitle
      ).sections; */
      this.dogBreedListModals = true;
      // console.log(match);
    },
    choosenDog(i) {
      console.log(i.breedName);
      this.$router.push({
        name: "BreederSearchResults",
        params: { id: i.breedName },
      });
    },
    closeModals() {
      this.dogBreedListModals = false;
      // console.log("close modals");
    },
  },
};
</script>

<style>
</style>