<template>
  <div class="pb-12">
    <div class="flex justify-start items-end">
      <div class="md:w-6/12">
        <img
          class="w-40 ml-28 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="$t('community_section_title')"
          titlemenu2="Breeder Directory"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <TopSection @resultSearch="resultSearch" />
      <ServiceSection />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import TopSection from "./TopSection.vue";
import ServiceSection from "./ServiceSection.vue";

import DataserviceLogo from "../../DataserviceLogo.vue";

export default {
  components: {
    HeaderGlobal,
    TopSection,
    ServiceSection,
    DataserviceLogo,
  },
  methods: {
    resultSearch(val) {
      console.log(val);
    },
  },
};
</script>

<style>
</style>